import { useState, useEffect, useCallback } from 'react';
import { searchConsoleClient } from '@/lib/services/search-console/client';
import { useAuth } from '@/hooks/useAuth';

export function useGscAuth() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [properties, setProperties] = useState<SearchConsoleProperty[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();

  const initialize = useCallback(async () => {
    if (!user?.uid) return;
    
    setIsInitializing(true);
    try {
      const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
      if (!clientId) {
        throw new Error('Google Client ID not found');
      }

      const authorized = await searchConsoleClient.initialize(clientId, user.uid);
      setIsAuthorized(authorized);
      
      // If authorized, fetch properties immediately
      if (authorized) {
        const fetchedProperties = await searchConsoleClient.fetchProperties();
        setProperties(fetchedProperties || []);
      }
    } catch (err: any) {
      console.error('Failed to initialize GSC:', err);
      setError(err);
      setIsAuthorized(false);
      setProperties([]);
    } finally {
      setIsInitializing(false);
    }
  }, [user?.uid]);

  const fetchProperties = useCallback(async () => {
    if (!isAuthorized) return;
    
    try {
      const fetchedProperties = await searchConsoleClient.fetchProperties();
      setProperties(fetchedProperties || []);
    } catch (err: any) {
      setError(err);
      setProperties([]);
    }
  }, [isAuthorized]);

  const authorize = useCallback(async () => {
    try {
      setError(null);
      await searchConsoleClient.requestAccessToken();
      setIsAuthorized(true);
      
      // Fetch properties after successful authorization
      const fetchedProperties = await searchConsoleClient.fetchProperties();
      setProperties(fetchedProperties || []);
    } catch (err: any) {
      console.error('Authorization failed:', err);
      setError(err);
      setIsAuthorized(false);
      setProperties([]);
      throw err;
    }
  }, []);

  useEffect(() => {
    if (user?.uid) {
      initialize();
    } else {
      setIsAuthorized(false);
      setProperties([]);
    }
  }, [user?.uid, initialize]);

  return {
    isAuthorized,
    isInitializing,
    properties,
    error,
    authorize,
    fetchProperties,
    initialize
  };
}
