import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import useProjects from '@/hooks/useProjects';
import {
  Home,
  Settings,
  Globe,
  FileText,
  Search,
  LineChart,
  Wrench,
  LogOut,
  User,
  ChevronUp
} from 'lucide-react';
import { Sidebar, SidebarContent, SidebarFooter } from '@/components/ui/sidebar';
import { useToast } from '@/hooks/use-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

// Utility function to convert business name to slug
const toSlug = (name: string) => 
  name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

const navigation = [
  { name: "Dashboard", href: "/", icon: Home },
  { name: "Content Analysis", href: "/content", icon: FileText },
  { name: "SEO Audit", href: "/audit", icon: Search },
  { name: "Performance", href: "/performance", icon: LineChart },
  { name: "Technical SEO", href: "/technical", icon: Wrench },
  { name: "Projects", href: "/projects", icon: Globe },
  { name: "Settings", href: "/settings", icon: Settings }
];

export const SidebarNav = () => {
  const { user, signOut } = useAuth();
  const { projects } = useProjects();
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleLogout = async () => {
    try {
      await signOut();
      toast({
        title: "Logged out successfully",
        description: "You have been logged out of your account.",
      });
      navigate("/login", { replace: true });
    } catch (error) {
      console.error("Error signing out:", error);
      toast({
        title: "Error logging out",
        description: "There was a problem logging you out. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Sidebar>
      <SidebarContent>
        <nav className="space-y-1 px-3 py-2">
          {navigation.map((item) => (
            <div key={item.href}>
              <Link to={item.href}>
                <Button
                  variant={location.pathname === item.href ? "secondary" : "ghost"}
                  className={cn(
                    "w-full justify-start gap-2 px-3 py-2",
                    location.pathname === item.href
                      ? "bg-secondary"
                      : "hover:bg-secondary/50"
                  )}
                >
                  <item.icon className="h-4 w-4" />
                  <span className="text-sm font-medium">{item.name}</span>
                </Button>
              </Link>

              {/* Project Submenu */}
              {item.name === "Projects" && projects.length > 0 && (
                <div className="pl-6 space-y-1 mt-1">
                  {projects.map((project) => (
                    <Link 
                      key={project.domain} 
                      to={`/projects/${toSlug(project.name)}`}
                    >
                      <Button
                        variant={
                          location.pathname === `/projects/${toSlug(project.name)}` 
                            ? "secondary" 
                            : "ghost"
                        }
                        className={cn(
                          "w-full justify-start gap-2 px-3 py-2 text-xs",
                          location.pathname === `/projects/${toSlug(project.name)}` 
                            ? "bg-secondary" 
                            : "hover:bg-secondary/50"
                        )}
                      >
                        <span>{project.name}</span>
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </SidebarContent>

      <SidebarFooter>
        <div className="relative px-3 py-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="w-full justify-start gap-2 px-3 py-2"
              >
                {user?.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt=""
                    className="h-6 w-6 rounded-full"
                    referrerPolicy="no-referrer"
                  />
                ) : (
                  <User className="h-6 w-6" />
                )}
                <span className="text-sm font-medium">
                  {user?.displayName || user?.email || "Profile"}
                </span>
                <ChevronUp className="ml-auto h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
              <DropdownMenuItem className="text-red-500" onClick={handleLogout}>
                <LogOut className="mr-2 h-4 w-4" />
                <span>Logout</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
};
