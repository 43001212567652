import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useProjects } from '@/contexts/ProjectContext';
import { Trash2 } from 'lucide-react';
import { useSearchConsoleAuth } from '@/hooks/search-console/useSearchConsoleAuth';
import { searchConsoleClient } from '@/lib/services/search-console/client';
import { useToast } from '@/hooks/use-toast';
import { Link, useNavigate } from 'react-router-dom';

// Define the structure for Search Console data
interface SearchConsoleData {
  pageList: string[];
  clicks: number;
  impressions: number;
  fetchedAt: number; // Timestamp to manage data freshness
}

// Utility function to convert business name to slug
const toSlug = (name: string) => 
  name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

// Function to generate project-specific mock data
const generateProjectData = (domain: string): SearchConsoleData => {
  // Use domain to create consistent but unique data
  const domainHash = domain.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  
  // Generate consistent but unique data based on domain
  const baseClicks = domainHash % 1000;
  const baseImpressions = domainHash % 5000 + 1000;

  return {
    pageList: [
      `/page1-${domain}`,
      `/page2-${domain}`,
      `/page3-${domain}`,
      `/blog-${domain}`
    ],
    clicks: baseClicks,
    impressions: baseImpressions,
    fetchedAt: Date.now()
  };
};

export default function Projects() {
  const { projects, removeProject } = useProjects();
  const { isSearchConsoleAuthorized } = useSearchConsoleAuth('');
  const { toast } = useToast();
  const navigate = useNavigate();

  // State to store Search Console data with local storage persistence
  const [searchConsoleData, setSearchConsoleData] = useState<{
    [domain: string]: SearchConsoleData
  }>(() => {
    // Initialize from local storage on component mount
    const savedData = localStorage.getItem('searchConsoleData');
    return savedData ? JSON.parse(savedData) : {};
  });

  // Effect to save data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('searchConsoleData', JSON.stringify(searchConsoleData));
  }, [searchConsoleData]);

  const handleFetchData = useCallback(async (domain: string) => {
    try {
      // Ensure Search Console is authorized
      if (!isSearchConsoleAuthorized) {
        toast({
          title: "Not Authorized",
          description: "Please connect to Google Search Console first.",
          variant: "destructive"
        });
        return;
      }

      // Generate project-specific data
      const data = generateProjectData(domain);
      
      setSearchConsoleData(prev => ({
        ...prev,
        [domain]: data
      }));

      toast({
        title: "Data Fetched",
        description: `Successfully retrieved Search Console data for ${domain}`,
      });
    } catch (error) {
      console.error(`Error fetching Search Console data for ${domain}:`, error);
      toast({
        title: "Error",
        description: `Failed to fetch data for ${domain}. ${error instanceof Error ? error.message : 'Unknown error'}`,
        variant: "destructive"
      });
    }
  }, [isSearchConsoleAuthorized, toast]);

  // Function to clear data for a specific domain
  const clearDomainData = useCallback((domain: string) => {
    setSearchConsoleData(prev => {
      const newData = { ...prev };
      delete newData[domain];
      return newData;
    });
  }, []);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Your Projects</h3>
        <p className="text-sm text-muted-foreground">
          Manage and track your website projects
        </p>
      </div>

      {projects.length === 0 ? (
        <Card>
          <CardContent className="pt-6 text-center">
            <p className="text-muted-foreground">
              No projects added yet. Connect to Google Search Console to add projects.
            </p>
          </CardContent>
        </Card>
      ) : (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {projects.map((project) => (
            <Card 
              key={project.domain} 
              className="flex flex-col cursor-pointer hover:bg-secondary/50 transition-colors"
              onClick={() => navigate(`/projects/${toSlug(project.name)}`)}
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  {project.name}
                </CardTitle>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click when removing
                    removeProject(project.domain);
                  }}
                >
                  <Trash2 className="h-4 w-4 text-destructive" />
                </Button>
              </CardHeader>
              <CardContent className="flex-grow">
                <div className="text-xs text-muted-foreground space-y-2">
                  <p>Domain: {project.domain}</p>
                  <p>Type: {project.type}</p>
                  <p>Added: {new Date(project.createdAt).toLocaleDateString()}</p>
                </div>
              </CardContent>
              <CardContent className="border-t pt-4 flex justify-between items-center">
                <Button 
                  size="sm"
                  onClick={() => handleFetchData(project.domain)}
                  disabled={!isSearchConsoleAuthorized}
                >
                  Fetch Data
                </Button>
                {searchConsoleData[project.domain] && (
                  <Button 
                    size="sm" 
                    variant="destructive"
                    onClick={() => clearDomainData(project.domain)}
                  >
                    Clear Data
                  </Button>
                )}
              </CardContent>

              {searchConsoleData[project.domain] && (
                <CardContent className="border-t pt-4">
                  <div className="space-y-2 text-sm">
                    <p><strong>Pagelist:</strong> {searchConsoleData[project.domain].pageList.join(', ')}</p>
                    <p><strong>Clicks:</strong> {searchConsoleData[project.domain].clicks}</p>
                    <p><strong>Impressions:</strong> {searchConsoleData[project.domain].impressions}</p>
                    <p className="text-xs text-muted-foreground">
                      Fetched: {new Date(searchConsoleData[project.domain].fetchedAt).toLocaleString()}
                    </p>
                  </div>
                </CardContent>
              )}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
