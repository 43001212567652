import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';

export default function ProjectDetail() {
  const { businessSlug } = useParams<{ businessSlug: string }>();

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium capitalize">
          {businessSlug?.replace(/-/g, ' ')}
        </h3>
        <p className="text-sm text-muted-foreground">
          Project details and analytics
        </p>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Project Overview</CardTitle>
        </CardHeader>
      </Card>
    </div>
  );
}
