import { useState, useEffect } from 'react';
import { searchConsoleClient } from '@/lib/services/search-console/client';

export const useSearchConsoleAuth = (userId: string) => {
  const [isSearchConsoleAuthorized, setIsSearchConsoleAuthorized] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    setIsSearchConsoleAuthorized(searchConsoleClient.isAuthorized());
    setIsInitializing(false);
  }, []);

  const initializeSearchConsole = async (clientId: string) => {
    try {
      await searchConsoleClient.initialize(clientId, userId);
      setIsInitializing(false);
    } catch (error) {
      console.error('Failed to initialize Search Console:', error);
      throw error;
    }
  };

  const authorizeSearchConsole = async () => {
    try {
      await searchConsoleClient.requestAccessToken();
      setIsSearchConsoleAuthorized(true);
    } catch (error) {
      console.error('Search Console authorization error:', error);
      setIsSearchConsoleAuthorized(false);
      throw error;
    }
  };

  const revokeSearchConsoleAccess = async () => {
    try {
      await searchConsoleClient.revokeAccess();
      setIsSearchConsoleAuthorized(false);
    } catch (error) {
      console.error('Failed to revoke Search Console access:', error);
      throw error;
    }
  };

  return {
    isSearchConsoleAuthorized,
    isInitializing,
    initializeSearchConsole,
    authorizeSearchConsole,
    revokeSearchConsoleAccess,
    getAccessToken: () => searchConsoleClient.getAccessToken()
  };
};
