import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { db } from '@/firebase';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useAuth';
import { firebaseAuth } from '@/lib/services/firebase-auth';

const GoogleLogin: React.FC = () => {
  const [error, setError] = useState('');
  const { user } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      console.log('Starting Google sign-in process...', { 
        dbInitialized: !!db
      });
      
      const user = await firebaseAuth.signInWithGoogle();
      console.log('Sign-in successful, user:', {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName
      });

      // Create or update user document in Firestore
      try {
        const userDocRef = doc(db, "users", user.uid);
        console.log('Attempting to create/update user document at:', {
          collection: 'users',
          docId: user.uid,
          path: userDocRef.path
        });
        
        // Basic user profile in main document
        const userData = {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          lastSignIn: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        console.log('User profile data to be stored:', userData);
        
        // Create/update main user document
        await setDoc(userDocRef, userData, { merge: true });
        console.log('User profile document successfully created/updated');

        // Initialize default user settings in a subcollection
        const settingsDocRef = doc(db, "users", user.uid, "settings", "preferences");
        const defaultSettings = {
          theme: 'light',
          emailNotifications: true,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        
        // Create settings document if it doesn't exist
        await setDoc(settingsDocRef, defaultSettings, { merge: true });
        console.log('User settings initialized');
        
        // Verify the documents were created
        const userDocSnap = await getDoc(userDocRef);
        const settingsDocSnap = await getDoc(settingsDocRef);
        
        console.log('Documents created:', {
          userProfile: userDocSnap.exists() ? 'exists' : 'missing',
          userData: userDocSnap.data(),
          settings: settingsDocSnap.exists() ? 'exists' : 'missing',
          settingsData: settingsDocSnap.data()
        });
      } catch (error) {
        const firestoreError = error as FirebaseError;
        console.error('Firestore operation failed:', {
          error: firestoreError,
          errorMessage: firestoreError.message,
          errorCode: firestoreError.code,
          errorStack: firestoreError.stack
        });
        throw firestoreError;
      }

      toast({
        title: "Successfully signed in",
        description: "Welcome to SEO Analysis",
      });

      // Navigate after everything is stored
      navigate("/");
    } catch (error) {
      const signInError = error as FirebaseError;
      console.error("Google Sign In Error:", signInError);
      setError(signInError.message);
      toast({
        title: "Error signing in",
        description: signInError.message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="w-full max-w-md space-y-8 rounded-lg bg-white p-6 shadow-lg">
        <div className="text-center">
          <h1 className="text-2xl font-bold tracking-tight">SEO Analysis</h1>
          <p className="mt-2 text-sm text-gray-600">Sign in to continue</p>
        </div>
        
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-800">{error}</p>
          </div>
        )}

        <Button
          variant="outline"
          onClick={handleGoogleLogin}
          className="w-full"
        >
          <svg className="mr-2 h-4 w-4" viewBox="0 0 24 24">
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
          </svg>
          Sign in with Google
        </Button>
      </div>
    </div>
  );
};

export default GoogleLogin;
