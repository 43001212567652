import { signInWithPopup, signOut, GoogleAuthProvider, User } from 'firebase/auth';
import { auth } from '@/firebase';

const googleProvider = new GoogleAuthProvider();

export const firebaseAuth = {
  // Get the current Firebase auth instance
  getInstance: () => auth,
  
  // Get current user
  getCurrentUser: () => auth.currentUser,
  
  // Sign in with Google
  signInWithGoogle: async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result.user;
    } catch (error) {
      console.error('Firebase auth error:', error);
      throw error;
    }
  },
  
  // Sign out
  signOut: async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  },
  
  // Listen to auth state changes
  onAuthStateChanged: (callback: (user: User | null) => void) => {
    return auth.onAuthStateChanged(callback);
  }
};
