import { useEffect, useState } from 'react';
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '@/firebase';
import { useAuth } from './useAuth';

export interface Project {
  id: string;
  name: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  settings?: {
    searchConsoleConnected?: boolean;
    openAiEnabled?: boolean;
  };
}

export function useProjects() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setProjects([]);
      setLoading(false);
      return;
    }

    try {
      const projectsRef = collection(db, 'projects');
      const projectsQuery = query(
        projectsRef,
        orderBy('updatedAt', 'desc')
      );

      const unsubscribe = onSnapshot(projectsQuery, (snapshot) => {
        const projectsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate(),
          updatedAt: doc.data().updatedAt?.toDate(),
        })) as Project[];

        setProjects(projectsData);
        setLoading(false);
      }, (err) => {
        setError(err);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (err) {
      setError(err as Error);
      setLoading(false);
    }
  }, [user]);

  return { projects, loading, error };
}

export default useProjects;
