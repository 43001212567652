import { useState } from 'react';
import { useGscAuth } from '@/hooks/useGscAuth';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';

export function GscConnection() {
  const { 
    isAuthorized,
    isInitializing,
    error,
    authorize,
    initialize
  } = useGscAuth();

  const handleConnect = async () => {
    try {
      await authorize();
    } catch (err) {
      console.error('Failed to connect to GSC:', err);
    }
  };

  if (isInitializing) {
    return (
      <Card className="p-6">
        <div className="flex items-center space-x-2">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>Initializing Google Search Console...</span>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="p-6">
        <div className="text-red-500">
          Error: {error.message}
        </div>
        <Button
          onClick={initialize}
          className="mt-4"
          variant="outline"
        >
          Retry Connection
        </Button>
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">
          {isAuthorized ? 'Google Search Console Status' : 'Connect to Google Search Console'}
        </h3>
        <Button 
          onClick={handleConnect}
          variant={isAuthorized ? "outline" : "default"}
        >
          {isAuthorized ? 'Reconnect GSC' : 'Connect GSC'}
        </Button>
      </div>
    </Card>
  );
}
