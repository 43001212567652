import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { useProjects } from "@/contexts/ProjectContext"
import { Link } from "react-router-dom"

export default function Dashboard() {
  const { projects } = useProjects();

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
        <p className="text-muted-foreground">
          SEO Analysis
        </p>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <Link to="/projects">
          <Card className="cursor-pointer transition-colors hover:bg-gray-100/50 dark:hover:bg-gray-800/50">
            <CardHeader>
              <CardTitle>Projects</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">{projects.length}</p>
              <p className="text-xs text-muted-foreground">
                Active projects
              </p>
            </CardContent>
          </Card>
        </Link>

        <Link to="/technical">
          <Card className="cursor-pointer transition-colors hover:bg-gray-100/50 dark:hover:bg-gray-800/50">
            <CardHeader>
              <CardTitle>Technical SEO</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">0</p>
              <p className="text-xs text-muted-foreground">
                Issues detected
              </p>
            </CardContent>
          </Card>
        </Link>

        <Link to="/audit">
          <Card className="cursor-pointer transition-colors hover:bg-gray-100/50 dark:hover:bg-gray-800/50">
            <CardHeader>
              <CardTitle>Audits</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">0</p>
              <p className="text-xs text-muted-foreground">
                Completed audits
              </p>
            </CardContent>
          </Card>
        </Link>

        <Link to="/performance">
          <Card className="cursor-pointer transition-colors hover:bg-gray-100/50 dark:hover:bg-gray-800/50">
            <CardHeader>
              <CardTitle>Performance</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">0</p>
              <p className="text-xs text-muted-foreground">
                Sites monitored
              </p>
            </CardContent>
          </Card>
        </Link>

        <Link to="/content">
          <Card className="cursor-pointer transition-colors hover:bg-gray-100/50 dark:hover:bg-gray-800/50">
            <CardHeader>
              <CardTitle>Contents</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">0</p>
              <p className="text-xs text-muted-foreground">
                Content optimizations
              </p>
            </CardContent>
          </Card>
        </Link>
      </div>
    </div>
  )
}
