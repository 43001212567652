import { loadScript } from '@/lib/utils/script-loader';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { SearchAnalyticsQuery, SearchAnalyticsResponse, SearchConsoleProperty } from './types';

const GOOGLE_SCRIPT_URL = 'https://accounts.google.com/gsi/client';
const SCOPES = ['https://www.googleapis.com/auth/webmasters.readonly']

interface TokenResponse {
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope: string;
  token_type: string;
  error?: string;
}

interface StoredTokens {
  accessToken: string;
  refreshToken?: string;
  expiryTime: number;
  userId: string;
}

class SearchConsoleClient {
  private clientId: string = '';
  private tokenClient: any = null;
  private accessToken: string | null = null;
  private refreshToken: string | null = null;
  private tokenExpiryTime: number = 0;
  private userId: string | null = null;
  private initialized: boolean = false;

  async initialize(clientId: string, userId: string) {
    if (!clientId || !userId) {
      throw new Error('Client ID and User ID are required');
    }
    
    this.clientId = clientId;
    this.userId = userId;
    
    try {
      await this.loadStoredTokens();
      await loadScript(GOOGLE_SCRIPT_URL);
      await this.waitForGoogleScript();
      
      this.tokenClient = (window as any).google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope: SCOPES.join(' '),
        redirect_uri: `${window.location.origin}/gsc-callback`,
        callback: this.handleTokenResponse.bind(this),
      });
      
      this.initialized = true;
      return this.isAuthorized();
    } catch (error) {
      console.error('Failed to initialize Search Console client:', error);
      throw error;
    }
  }

  private async waitForGoogleScript(maxAttempts = 10, delayMs = 100): Promise<void> {
    for (let i = 0; i < maxAttempts; i++) {
      if ((window as any).google?.accounts?.oauth2) {
        return;
      }
      await new Promise(resolve => setTimeout(resolve, delayMs));
    }
    throw new Error('Google Identity Services script failed to load');
  }

  isAuthorized(): boolean {
    return !!(
      this.initialized &&
      this.accessToken &&
      this.tokenExpiryTime > Date.now()
    );
  }

  private async handleTokenResponse(response: TokenResponse) {
    if (response.error) {
      throw new Error(response.error);
    }
    
    this.accessToken = response.access_token;
    if (response.refresh_token) {
      this.refreshToken = response.refresh_token;
    }
    this.tokenExpiryTime = Date.now() + (response.expires_in * 1000);
    
    await this.storeTokens();
  }

  private async storeTokens() {
    if (!this.userId) {
      console.error('No userId available for storing tokens');
      return;
    }

    const tokens: StoredTokens = {
      accessToken: this.accessToken!,
      expiryTime: this.tokenExpiryTime,
      userId: this.userId,
    };
    if (this.refreshToken) {
      tokens.refreshToken = this.refreshToken;
    }

    const tokenDoc = doc(db, 'users', this.userId, 'search-console', 'tokens');
    await setDoc(tokenDoc, tokens);
  }

  private async loadStoredTokens() {
    if (!this.userId) {
      console.error('No userId available for loading tokens');
      return;
    }

    const tokenDoc = doc(db, 'users', this.userId, 'search-console', 'tokens');
    const tokenSnap = await getDoc(tokenDoc);
    
    if (tokenSnap.exists()) {
      try {
        const tokens = tokenSnap.data() as StoredTokens;
        this.accessToken = tokens.accessToken;
        this.tokenExpiryTime = tokens.expiryTime;
        this.refreshToken = tokens.refreshToken || null;
        console.log('Loaded stored tokens successfully');
      } catch (error) {
        console.error('Error parsing stored tokens:', error);
        await this.clearTokens();
      }
    } else {
      console.log('No stored tokens found');
    }
  }

  private async clearTokens() {
    if (!this.userId) return;

    this.accessToken = null;
    this.refreshToken = null;
    this.tokenExpiryTime = 0;
    
    const tokenDoc = doc(db, 'users', this.userId, 'search-console', 'tokens');
    await setDoc(tokenDoc, {});
    console.log('Tokens cleared');
  }

  async refreshAccessToken(): Promise<string> {
    console.log('Attempting to refresh access token');
    if (!this.refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: this.clientId,
          grant_type: 'refresh_token',
          refresh_token: this.refreshToken,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Token refresh failed:', errorData);
        throw new Error(`Failed to refresh access token: ${errorData.error_description || response.statusText}`);
      }

      const data: TokenResponse = await response.json();
      await this.handleTokenResponse(data);
      console.log('Access token refreshed successfully');
      return data.access_token;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  }

  async requestAccessToken(): Promise<string> {
    console.log('Requesting access token');
    if (!this.initialized) {
      throw new Error('Search Console client not initialized. Call initialize() first.');
    }

    // Check if we have a valid token
    if (this.accessToken && Date.now() < this.tokenExpiryTime - 60000) {
      console.log('Using existing valid token');
      return this.accessToken;
    }

    // Try to refresh the token if we have a refresh token
    if (this.refreshToken) {
      try {
        console.log('Attempting to refresh token');
        const token = await this.refreshAccessToken();
        // After successful refresh, we're authorized
        return token;
      } catch (error) {
        console.error('Token refresh failed:', error);
        // Clear tokens on refresh failure to force new auth
        await this.clearTokens();
      }
    }

    // Ensure Google script is loaded and token client is initialized
    if (!this.tokenClient) {
      await this.waitForGoogleScript();
      this.tokenClient = (window as any).google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope: SCOPES.join(' '),
        callback: this.handleTokenResponse.bind(this),
        prompt: 'consent'
      });
    }

    return new Promise((resolve, reject) => {
      try {
        this.tokenClient.callback = (response: TokenResponse) => {
          if (response.error) {
            reject(new Error(response.error));
            return;
          }
          this.handleTokenResponse(response)
            .then(() => resolve(response.access_token))
            .catch(reject);
        };
        
        this.tokenClient.requestAccessToken({ prompt: 'consent' });
      } catch (error) {
        reject(error);
      }
    });
  }

  async revokeAccess() {
    console.log('Revoking access');
    if (this.accessToken) {
      try {
        const revokeEndpoint = `https://oauth2.googleapis.com/revoke?token=${this.accessToken}`;
        await fetch(revokeEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        await this.clearTokens();
        console.log('Access revoked successfully');
      } catch (error) {
        console.error('Error revoking access:', error);
        throw error;
      }
    }
  }

  async fetchProperties(): Promise<SearchConsoleProperty[]> {
    console.log('fetchProperties called');
    if (!this.initialized) {
      console.error('Search Console client not initialized');
      throw new Error('Search Console client not initialized. Call initialize() first.');
    }

    try {
      const accessToken = await this.requestAccessToken();
      console.log('Access token retrieved for fetching properties');
      const response = await fetch('https://www.googleapis.com/webmasters/v3/sites', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to fetch properties:', errorData);
        throw new Error(`Failed to fetch properties: ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      console.log('Properties fetched successfully');
      return data.siteEntry || [];
    } catch (error) {
      console.error('Error in fetchProperties:', error);
      throw error;
    }
  }

  async fetchAnalytics(
    siteUrl: string,
    query: SearchAnalyticsQuery
  ): Promise<SearchAnalyticsResponse> {
    console.log('Fetching analytics...', { siteUrl, query });
    if (!this.initialized) {
      throw new Error('Search Console client not initialized. Call initialize() first.');
    }

    try {
      const accessToken = await this.requestAccessToken();
      const response = await fetch(
        `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(query)
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Analytics API error:', errorData);
        throw new Error(`Failed to fetch analytics: ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      console.log('Analytics fetched successfully');
      return data;
    } catch (error) {
      console.error('Error fetching analytics:', error);
      throw error;
    }
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }
}

export const searchConsoleClient = new SearchConsoleClient();
