import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/hooks/use-toast';
import { useGscAuth } from '@/hooks/useGscAuth';
import { useProjects } from '@/contexts/ProjectContext';
import { useMemo } from 'react';
import { Plus } from 'lucide-react';
import { GscConnection } from '@/components/search-console/GscConnection';

export default function Settings() {
  const { user } = useAuth();
  const { toast } = useToast();
  const { properties } = useGscAuth();
  const { projects, addProject } = useProjects();

  // Compute properties not yet added to projects, sorted alphabetically
  const combinedProperties = useMemo(() => {
    // First, get the available properties not yet added to projects
    const availableProps = properties
      .filter(prop => !projects.some(proj => proj.domain === prop.siteUrl.replace(/^sc-domain:/, '')))
      .map(prop => ({
        ...prop,
        cleanSiteUrl: prop.siteUrl.replace(/^sc-domain:/, ''),
        isAvailable: true
      }));

    // Then, add the existing projects
    const existingProjects = projects.map(proj => ({
      siteUrl: proj.domain,
      cleanSiteUrl: proj.domain,
      permissionLevel: proj.type,
      isAvailable: false,
      createdAt: proj.createdAt
    }));

    // Combine and sort
    return [...availableProps, ...existingProjects]
      .sort((a, b) => a.cleanSiteUrl.localeCompare(b.cleanSiteUrl));
  }, [properties, projects]);

  const handleAddProject = (property: { siteUrl: string, permissionLevel: string }) => {
    try {
      // Remove sc-domain: prefix if present
      const cleanSiteUrl = property.siteUrl.replace(/^sc-domain:/, '');

      // Validate URL
      const url = new URL(cleanSiteUrl.startsWith('http') ? cleanSiteUrl : `https://${cleanSiteUrl}`);

      // Extract business name from URL
      const businessName = url.hostname
        .replace('www.', '')
        .split('.')[0]
        .replace(/^\w/, c => c.toUpperCase());

      // Replace user type labels
      const formattedPermissionLevel = 
        property.permissionLevel === 'siteOwner' ? 'Owner' :
        property.permissionLevel === 'siteFullUser' ? 'Administrator' :
        property.permissionLevel === 'siteUnverifiedUser' ? 'Unverified User' :
        property.permissionLevel;

      const newProject = {
        name: businessName,
        domain: cleanSiteUrl,
        type: formattedPermissionLevel,
        createdAt: new Date().toISOString()
      };

      // Log for debugging
      console.log('Adding project:', newProject);

      // Add project
      addProject(newProject);
      
      toast({
        title: "Project Added",
        description: `${businessName} has been added to your projects.`,
      });
    } catch (error) {
      console.error('Error adding project:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add project. Please check the URL.",
      });
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Settings</h3>
        <p className="text-sm text-muted-foreground">
          Manage your account settings and preferences.
        </p>
      </div>

      <Tabs defaultValue="search-console">
        <TabsList>
          <TabsTrigger value="search-console">Search Console</TabsTrigger>
          <TabsTrigger value="general">General</TabsTrigger>
        </TabsList>

        <TabsContent value="search-console" className="space-y-4">
          <GscConnection />
          <Card>
            <CardHeader>
              <CardTitle>Properties</CardTitle>
              <CardDescription>
                Manage your Search Console properties
              </CardDescription>
            </CardHeader>
            <CardContent>
              {combinedProperties.length > 0 ? (
                <ul className="space-y-2">
                  {combinedProperties.map((property) => (
                    <li 
                      key={property.siteUrl} 
                      className="flex items-center justify-between space-x-2 border-b pb-2 last:border-b-0"
                    >
                      <div className="flex items-center space-x-2">
                        <span>{property.cleanSiteUrl}</span>
                        <span className="text-sm text-muted-foreground">
                          ({
                            property.permissionLevel === 'siteOwner' ? 'Owner' :
                            property.permissionLevel === 'siteFullUser' ? 'Administrator' :
                            property.permissionLevel === 'siteUnverifiedUser' ? 'Unverified User' :
                            property.permissionLevel
                          })
                        </span>
                      </div>
                      {property.isAvailable && (
                        <Button 
                          size="sm" 
                          variant="outline"
                          onClick={() => handleAddProject(property)}
                        >
                          <Plus className="mr-1 h-4 w-4" /> Add Project
                        </Button>
                      )}
                      {!property.isAvailable && (
                        <span className="text-xs text-muted-foreground">
                          Added: {new Date(property.createdAt).toLocaleDateString()}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted-foreground">
                  No properties available or added.
                </p>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="general">
          <Card>
            <CardHeader>
              <CardTitle>API Keys</CardTitle>
              <CardDescription>
                Configure your API keys for various services.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <Label htmlFor="jinaAiKey">Jina AI Key</Label>
                <Input
                  id="jinaAiKey"
                  placeholder="Enter your Jina AI key"
                  type="password"
                />
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
