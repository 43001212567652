import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGscAuth } from '@/hooks/useGscAuth';
import { Card } from '@/components/ui/card';

export function GscCallback() {
  const navigate = useNavigate();
  const { handleGscCallback, initializeGsc } = useGscAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const processCallback = async () => {
      // Get the authorization code from URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');

      if (error) {
        console.error('OAuth error from Google:', error);
        setError(error);
        return;
      }
 
      if (!code) {
        console.error('No authorization code received');
        setError('No authorization code received');
        return;
      }

      try {
        console.log('Processing OAuth callback with code');
        await handleGscCallback(code);
        await initializeGsc();
        console.log('OAuth callback processed successfully');
        
        // Close the popup if we're in one
        if (window.opener) {
          window.opener.postMessage({ type: 'GSC_AUTH_SUCCESS' }, window.location.origin);
          window.close();
        } else {
          // If not in popup, redirect back to the main page
          navigate('/');
        }
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to authenticate with GSC';
        console.error('OAuth callback processing error:', errorMessage);
        setError(errorMessage);
      }
    };

    processCallback();
  }, [handleGscCallback, navigate, initializeGsc]);

  if (error) {
    return (
      <Card className="p-6 max-w-md mx-auto mt-8">
        <h2 className="text-lg font-semibold text-red-600 mb-4">Authentication Error</h2>
        <p className="text-gray-600">{error}</p>
      </Card>
    );
  }

  return (
    <Card className="p-6 max-w-md mx-auto mt-8">
      <h2 className="text-lg font-semibold mb-4">Connecting to Google Search Console</h2>
      <p className="text-gray-600">Please wait while we complete the authentication process...</p>
    </Card>
  );
}
